import React from "react";
import { TopNavigationProps } from "@amzn/awsui-components-react/uxdg/top-navigation/interfaces";
import Utility = TopNavigationProps.Utility;
import { TopNavigation } from "@amzn/awsui-components-react";
import { GetRegionsFromLocalStorage, GetUserFromLocalStorage } from "../utils/dreamscape";
import { Regions, User } from "../dreamscape-api/generated-src";

interface TopNavProps {

}

interface TopNavState {
  user: User
  regions: Regions
  topNavUtils: Utility[]
}

export class TopNav extends React.Component<TopNavProps, TopNavState> {

  constructor(props: TopNavProps) {
    super(props);
    this.state = {
      user: GetUserFromLocalStorage(),
      regions: GetRegionsFromLocalStorage(),
      topNavUtils: []
    }
  }

  componentDidMount() {
    const currentRegion = this.state.regions.currentRegion || {};
    const regions = this.state.regions.regions?.map(it => it) || [];
    const domainSuffix = window.location.hostname.replace(new RegExp(`^${currentRegion?.airportCode?.toLowerCase()}[.]`), '')
    this.setState({
      topNavUtils: [
        {
          type: "menu-dropdown",
          iconName: "settings",
          ariaLabel: "Settings",
          title: "Settings",
          items: [
            {
              id: "settings-org",
              text: "Application Settings"
            }
          ]
        },
        {
          type: "menu-dropdown",
          text: this.state.user.userName,
          iconName: "user-profile",
          items: []
        },
        {
          type: "menu-dropdown",
          text: `${currentRegion?.airportCode} (${currentRegion?.regionName})`,
          items: regions.sort((a, b) => {
            return (a.airportCode || "").localeCompare(b.airportCode || "")
          }).map((r) => {
              return {
                id: r.airportCode || "",
                text: `${r.airportCode} (${r.regionName})`,
                href: `https://${r.airportCode?.toLocaleLowerCase()}.${domainSuffix.toLowerCase()}`
              }
            }
          )
        }
      ]
    });
  }

  render() {
    return (
      <TopNavigation
        i18nStrings={{
          searchIconAriaLabel: 'Search',
          searchDismissIconAriaLabel: 'Close search',
          overflowMenuTriggerText: 'More',
          overflowMenuTitleText: 'DS'
        }}
        identity={{
          href: '#',
          title: 'Dreamscape'
        }}
        utilities={this.state.topNavUtils}
      />

    )
  }
}