import React, {useEffect, useState} from "react";
import 'ace-builds/css/ace.css';
import 'ace-builds/css/theme/dawn.css';
import 'ace-builds/css/theme/tomorrow_night_bright.css';
import { useLocation, Redirect } from "react-router-dom";
import {
    Header,
    Link,
    SpaceBetween,
    Container,
    Form,
    Button,
    ButtonDropdown,
    SegmentedControl,
    Popover,
    HelpPanel, ExpandableSection,
    Select
} from "@amzn/awsui-components-react";
import CodeEditor from '@amzn/awsui-components-react/polaris/code-editor';

const i18nStrings = {
    loadingState: 'Loading code editor',
    errorState: 'There was an error loading the code editor.',
    errorStateRecovery: 'Retry',

    editorGroupAriaLabel: 'Code editor',
    statusBarGroupAriaLabel: 'Status bar',

    cursorPosition: (row, column) => `Ln ${row}, Col ${column}`,
    errorsTab: 'Errors',
    warningsTab: 'Warnings',
    preferencesButtonAriaLabel: 'Preferences',

    paneCloseButtonAriaLabel: 'Close',

    preferencesModalHeader: 'Preferences',
    preferencesModalCancel: 'Cancel',
    preferencesModalConfirm: 'Confirm',
    preferencesModalWrapLines: 'Wrap lines',
    preferencesModalTheme: 'Theme',
    preferencesModalLightThemes: 'Light themes',
    preferencesModalDarkThemes: 'Dark themes',
};


const PolicyGenerator: React.FC = () => {

    interface LocationState {
        policyData: any;
    }

    interface SelectedOption {
        label: string;
    }

    // Get the location state
    const location = useLocation<LocationState>();
    const [ace, setAce] = useState<any>();
    const [loading, setLoading] = useState(true);

    // Define state variables
    const [selectedId, setSelectedId] = useState("json");
    const [selectedEvent, setSelectedEvent] = useState({label: 'Choose a service'});
    const [value, setValue] = useState('');
    const [preferences, setPreferences] = useState({});
    const [siteRedirect, setSiteRedirect] = useState(false);

    useEffect(() => {
        import('ace-builds')
            .then((ace) => {
                import('ace-builds/webpack-resolver')
                    .then(() => {
                        setAce(ace);
                        setLoading(false);
                    })
                    .catch(() => setLoading(false));
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        setValue(location.state?.policyData || '');
    }, [location.state]);

    const redirectToPolicyViewer = () => {
        if (siteRedirect) {
            return (
                <Redirect
                    to={{
                        pathname: '/totem/policy-viewer'
                    }}
                />
            );
        } else {
            return null;
        }
    }

    const policyTemplate = {
        "template_version": "",
        "description": "Sample permission",
        "onboarding_issue": "",
        "posix_link": "",
        "contact": {
            "cti": "",
            "resolver_group": "",
            "email": ""
        },
        "principal": {
            "posix_group": ""
        },
        "AuthPermissionIAM": {
            "Version": "",
            "Statement": [
                {
                    "Action": "",
                    "Effect": "",
                    "Resource": "",
                    "Condition": {
                        "StringLike": {
                            "": [
                                ""
                            ]
                        }
                    }
                },
                {
                    "Action": "",
                    "Effect": "",
                    "Resource": "",
                    "Condition": {
                        "StringLike": {
                            "": [
                                ""
                            ]
                        }
                    }
                }
            ]
        },
        "policy_version": ""
    };

    return (
        <form onSubmit={e => e.preventDefault()}>
            <Form
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button formAction="none" onClick={() => setSiteRedirect(true)} variant="link">
                            Cancel
                        </Button>
                        {redirectToPolicyViewer()}
                        <Button variant="primary">Next</Button>
                    </SpaceBetween>
                }
                header={
                    <main>
                        <Header
                            variant="h1"
                            description="Build permission statements using the editor by
                            updating actions, resources, and conditions."
                            info={<Popover
                                size={"medium"}
                                triggerType="custom"
                                content={
                                    <HelpPanel
                                        footer={
                                            <div>
                                                <h3>Learn more</h3>
                                                <Link
                                                    external
                                                    href="https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies.html?icmpid=docs_iam_help_panel"
                                                >
                                                    Policies and permissions in IAM
                                                </Link>
                                            </div>
                                        }
                                        header={<h2>Policies</h2>}
                                    >
                                        <div>
                                            <p>
                                                Policies are JSON documents in AWS that let
                                                you specify who has access to AWS resources,
                                                and what actions they can perform on those
                                                resources. You can attach a policy to an
                                                identity or resource to define their
                                                permissions. AWS evaluates these policies
                                                when the IAM principal makes a request.
                                                Permissions in the policies determine
                                                whether the request is allowed or denied.
                                            </p>
                                        </div>
                                    </HelpPanel>
                                }
                            >
                                {" "}
                                <Link variant="info">Info</Link>
                            </Popover>}

                            actions={
                                <SpaceBetween direction="horizontal" size="xs">
                                    <Button
                                        variant={"primary"}
                                        onClick={() => setValue(JSON.stringify(policyTemplate, null, 2))}
                                    >
                                        Add Policy Template
                                    </Button>
                                </SpaceBetween>
                            }
                        >
                            Specify Permissions
                        </Header>
                    </main>
                }
            >
            <div style={{padding: 0}}>
                <Container>
                    <Header
                        variant="h2"
                    >
                        Policy Editor
                    </Header>
                </Container>
            </div>

                {
                    selectedId === "json" ? (
                            <SpaceBetween direction="vertical" size="l">
                                <CodeEditor
                                    ace={ace}
                                    value={value}
                                    language="json"
                                    onDelayedChange={event => setValue(event.detail.value)}
                                    preferences={preferences}
                                    onPreferencesChange={event => setPreferences(event.detail)}
                                    loading={loading}
                                    i18nStrings={i18nStrings}
                                    themes={{ light: ['dawn'], dark: ['tomorrow_night_bright'] }}
                                    onRecoveryClick={() => {
                                        import('ace-builds')
                                            .then((ace) => {
                                                import('ace-builds/webpack-resolver')
                                                    .then(() => {
                                                        setAce(ace);
                                                        setLoading(false);
                                                    })
                                                    .catch(() => setLoading(false));
                                            })
                                            .catch(() => {
                                                setLoading(false);
                                            });
                                    }}
                                />
                            </SpaceBetween>
                        ) : (
                        <div style={{marginTop: 10}}>
                            <ExpandableSection
                                defaultExpanded
                                variant="container"
                                headerText="IAM Policy"
                            >
                                <b>Service</b>
                                <div style={{paddingTop: 4}}>
                                </div>
                            </ExpandableSection>
                        </div>
                    )
                }
            </Form>
        </form>
    );
};

export default PolicyGenerator;