// Promise polyfill
import 'core-js/features/promise';
import React from "react";
import ReactDOM from "react-dom";
import App from './components/App';
import '@amzn/awsui-global-styles/polaris.css';
import { TopNav } from "./components/TopNav";
import { hideLoading, showLoading } from "./components/Loading";
import { getMidwayJwtToken } from "./auth/MidwayJwtToken";
import axios from "axios";
import { initializeAppSettings } from "./config/AppSettings";
import DreamscapeApiFactory from "./dreamscape-api/DreamscapeApiFactory";
import { User, Regions } from "@amzn/coral_com-amazonaws-console-dreamscape-model";

// Add Authorization header to all axios calls
axios.interceptors.request.use(async (config) => {
  const jwtToken = await getMidwayJwtToken();
  config.headers.Authorization = `Bearer ${jwtToken}`
  return config;
});

const renderApp = () => {
  ReactDOM.render(
    <TopNav/>,
    document.getElementById('h')
  )
  ReactDOM.render(
    <App/>,
    document.getElementById('app')
  );
}

(async () => {
  // Make sure Midway is present before rendering
  await getMidwayJwtToken();

  // Initialize application settings
  const appSettings = (await axios('/settings.json')).data;
  initializeAppSettings(appSettings);

  const dreamscapeApi = DreamscapeApiFactory();
  showLoading();
  dreamscapeApi.whoAmI()
  .then(resp => {
    const user = resp.data.user!;
    dreamscapeApi.listRegions()
    .then(resp => {
      const regions = resp.data.regions!;
      localStorage.setItem("user", JSON.stringify(User.toJson(user)));
      localStorage.setItem("regions", JSON.stringify(Regions.toJson(regions)));
      renderApp();
      hideLoading();
    })
    .catch(reason => {
      renderApp();
      hideLoading();
    })
  })
  .catch(reason => {
      renderApp();
      hideLoading();
  });
})();
