import React from "react";
import { Redirect } from "react-router-dom";
import {
    SpaceBetween, Header, Button,
    Link, ContentLayout,
    ExpandableSection, HelpPanel, Popover,
    Select, Modal, Alert, Table, Box
} from "@amzn/awsui-components-react";
import { DefaultApi } from "../dreamscape-api/generated-src";
import DreamscapeApiFactory from "../dreamscape-api/DreamscapeApiFactory";

interface OptionDefinition {
    label: string;
    value: string;
}

interface PolicyViewerProps {

}

interface PolicyViewerState {
    showErrorModal: boolean;
    errorMessage: string;
    errorMessages: string[] | undefined;
    siteRedirect: boolean;
    showPolicyTable: boolean;
    totemObjects: any[];
    dropdownOptions: OptionDefinition[];
    selectedPolicy: string;
    objectsList: {
        Key: string;
        LastModified: string;
    }[];
    policyData: any;

}

export const PolicyViewer = () => {
    return (
        <PolicyViewerComponent/>
    );
}

export class PolicyViewerComponent extends React.Component<PolicyViewerProps, PolicyViewerState> {
    dreamscapeApi: DefaultApi
    selectedEvent: OptionDefinition | null;

    constructor(props: PolicyViewerProps) {
        super(props);
        this.state = {
            showErrorModal: false,
            errorMessage: "",
            errorMessages: undefined,
            siteRedirect: false,
            showPolicyTable: false,
            totemObjects: [],
            dropdownOptions: [],
            selectedPolicy: "",
            objectsList: [],
            policyData: null
        }
        this.dreamscapeApi = DreamscapeApiFactory();
        this.selectedEvent = { label: 'Choose a service', value: ''};
    }

    // Fetch dropdown options when component mounts
    componentDidMount() {
        this.fetchDropdownOptions();
    }

    // Fetch dropdown options from API
    fetchDropdownOptions = () => {
        this.dreamscapeApi.getTotemS3Objects("customerpolicybucket-pdx-alpha")
            .then(resp => {
                if (resp.data.error) {
                    this.showError(resp.data.error, resp.data.errors);
                } else {
                    const objectsList = resp.data.objectsList || [];
                    const retrievedObjects = objectsList.map(obj => {
                        if (typeof obj === 'string') {
                            return { Key: obj, LastModified: "" };
                        } else {
                            return obj;
                        }
                    });
                    const uniqueServiceNames = [...new Set(retrievedObjects.map(service => service.Key.split("/")[0]))];
                    const dropdownOptions = uniqueServiceNames.map(label => ({
                        label: label.charAt(0).toUpperCase() + label.slice(1),
                        value: label
                    }));
                    this.setState({ dropdownOptions });
                }
            })
            .catch(error => {
                this.showError("Error fetching dropdown options.");
                console.error(error);
            });
    }

    // Redirect to Policy Generator page with policy data
    redirectToPolicyGenerator = () => {
        const { siteRedirect } = this.state;

        if (siteRedirect) {
            return <Redirect
                to={{
                    pathname: '/totem/policy-generator',
                    state: {
                        policyData: this.state.policyData
                    }
                }}
            />;
        } else {
            return null;
        }
    }

    showError = (message: string, messages: string[] | undefined = undefined) => {
        this.setState({
            showErrorModal: true,
            errorMessage: message,
            errorMessages: messages
        });
    }

    errorMessageList = (errorMessages: string[] | undefined) => {
        if (errorMessages === undefined) {
            return <></>;
        }
        const errorMessageListItems = errorMessages.map((errorMessage) => {
            return <li>{errorMessage}</li>;
        });
        return <ul>{errorMessageListItems}</ul>
    }

    // Fetch objects for selected service from API
    getTotemObjects = (selectedOption: OptionDefinition) => {
        this.selectedEvent = selectedOption;
        this.dreamscapeApi.getTotemS3Objects(
            "customerpolicybucket-pdx-alpha",
            selectedOption.value,
        )
            .then(resp => {
                if (resp.data.error) {
                    this.showError(resp.data.error, resp.data.errors)
                    return;
                }
                if (resp.data.objectsList !== undefined) {
                    const objectsList = (resp.data.objectsList || []).map(key => {
                        if (typeof key === 'string') {
                            return { Key: key, LastModified: '' };
                        } else {
                            return key;
                        }
                    });
                    const fileNames = objectsList.map(obj =>
                        obj.Key.split("/").pop()
                    );
                    this.setState({
                        totemObjects: fileNames,
                        showPolicyTable: true,
                        objectsList
                    })
                }
            })
            .catch(reason => {
                this.showError("Cannot find requested service.")
                console.log(reason)
            });
    }

    // Handle click on a policy in the table
    handlePolicyClick = async (policy: string) => {
        await this.dreamscapeApi.getTotemS3ObjectsData(
            "customerpolicybucket-pdx-alpha",
            policy
        )
            .then(policyData => {
                if (policyData.data.error) {
                    this.showError(policyData.data.error, policyData.data.errors)
                    return;
                }
                if (policyData.data.contents !== undefined) {
                    this.setState({
                        siteRedirect: true,
                        policyData: policyData.data.contents
                    })
                }
            })
            .catch(reason => {
                this.showError("Cannot find requested policy.")
                console.log(reason)
            });
    }


    // Show the policies table
    showPolicies = () => {
        const { showPolicyTable } = this.state;

        if (showPolicyTable) {
            return (
                <Table
                    columnDefinitions={[
                        {
                            id: "policy",
                            header: "Policy name",
                            cell: (item) => (
                                <div onClick={() => this.handlePolicyClick(item.Key)}>
                                    <Link>{item.Key}</Link>
                                </div>
                            ),
                            sortingField: "name",
                            isRowHeader: true
                        },
                        {
                            id: "lastModified",
                            header: "Last Modified",
                            cell: (item) => {
                                const date = new Date(item.LastModified);
                                return date.toLocaleDateString();
                            },
                            sortingField: "lastModified"
                        }
                    ]}
                    columnDisplay={[
                        { id: "policy", visible: true },
                        { id: "lastModified", visible: true },
                    ]}
                    items={this.state.objectsList}
                    loadingText="Loading resources"
                    sortingDisabled
                    empty={
                        <Box margin={{ vertical: "xs" }} textAlign="center" color="inherit">
                            <SpaceBetween size="m">
                                <b>No resources</b>
                            </SpaceBetween>
                        </Box>
                    }
                />
            );
        } else {
            return null;
        }
    };

    render = () => {
        return <>
            <ContentLayout>
                <main style={{marginBottom: 18}}>
                    <Header
                        variant="h1"
                        description="A policy is an object in AWS that defines permissions."
                        actions={
                            <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                    variant={"primary"}
                                    onClick={() => {
                                        this.setState({
                                            siteRedirect: true
                                        });
                                    }}
                                >
                                    Create New Policy
                                </Button>
                                {this.redirectToPolicyGenerator()}
                            </SpaceBetween>
                        }
                        info={<Popover
                            size={"medium"}
                            triggerType="custom"
                            content={
                                <HelpPanel
                                    footer={
                                        <div>
                                            <h3>Learn more</h3>
                                            <Link
                                                external
                                                href="https://docs.aws.amazon.com/IAM/latest/UserGuide/access_policies.html?icmpid=docs_iam_help_panel"
                                            >
                                                Policies and permissions in IAM
                                            </Link>
                                        </div>
                                    }
                                    header={<h2>Policies</h2>}
                                >
                                    <div>
                                        <p>
                                            Policies are JSON documents in AWS that let
                                            you specify who has access to AWS resources,
                                            and what actions they can perform on those
                                            resources. You can attach a policy to an
                                            identity or resource to define their
                                            permissions. AWS evaluates these policies
                                            when the IAM principal makes a request.
                                            Permissions in the policies determine
                                            whether the request is allowed or denied.
                                        </p>
                                    </div>
                                </HelpPanel>
                            }
                        >
                            {" "}
                            <Link variant="info">Info</Link>
                        </Popover>}
                    >
                        Policies
                    </Header>
                </main>

                <Modal
                    visible={this.state.showErrorModal}
                    header={"Error"}
                    size={"medium"}
                    onDismiss={() => {
                        this.setState({ showErrorModal: false, errorMessage: "" })
                    }}
                    footer={""}
                >
                    <Alert
                        header={"Error"}
                        type={"error"}
                    >
                        {this.state.errorMessage}
                        {this.errorMessageList(this.state.errorMessages)}
                    </Alert>
                </Modal>

                <ExpandableSection
                    defaultExpanded
                    variant="container"
                    headerText="Filter by service"
                    headerDescription="Select a service to view related resources."
                >
                    <div style={{paddingTop: 4}}>
                        <Select
                            selectedOption={this.selectedEvent}
                            onChange={
                                (event) => {
                                    const selectedOption = event.detail.selectedOption as OptionDefinition;
                                    this.getTotemObjects(selectedOption);
                                }}
                            options={this.state.dropdownOptions}
                            filteringPlaceholder="Filter services"
                            filteringType="auto"
                            placeholder="Choose a service"
                            noMatch={
                                <React.Fragment>Service not found</React.Fragment>
                            }
                            selectedAriaLabel="Select"
                        />
                    </div>

                    <div style={{marginTop: 18}}>
                        {this.showPolicies()}
                    </div>
                </ExpandableSection>
            </ContentLayout>
        </>;
    }
}