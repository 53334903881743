import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import {
  AppLayout, NonCancelableCustomEvent,
  SideNavigation,
} from "@amzn/awsui-components-react";
import { Home } from "./Home";
import { XtermSession } from "./XtermSession";
import { AppLayoutProps } from "@amzn/awsui-components-react/polaris/app-layout/interfaces";
import { PolicyViewer } from "./PolicyViewer";
import PolicyGenerator from "./PolicyGenerator";

interface AppProps {

}

interface AppState {
  navigationOpen: boolean
}

class App extends React.Component<AppProps, AppState> {
  constructor(props: AppProps) {
    super(props)
    this.state = {
      navigationOpen: true
    }
  }

  render() {
    return (
      <HashRouter>
        <AppLayout
          navigationOpen={this.state.navigationOpen}
          onNavigationChange={(event: NonCancelableCustomEvent<AppLayoutProps.ChangeDetail>) => this.setState({ navigationOpen: event.detail.open })}
          navigationWidth={200}
          headerSelector={"#h"}
          navigation={<SideNavigation
            items={[
              {
                type: "section",
                text: "Console Bastion",
                items: [
                  { type: "link", text: "Sessions", href: "#/bastion/session" }
                ]
              },
              {type: "divider"},
              {
                type: "section",
                text: "Totem Service",
                items: [
                  { type: "link", text: "Policy Viewer", href: "#/totem/policy-viewer" },
                  { type: "link", text: "Policy Generator", href: "#/totem/policy-generator" }
                ]
              },
              // { type: "divider" },
              // {
              //   type: "section",
              //   text: "RunBMC",
              //   items: [
              //     { type: "link", text: "Reboot", href: "#/runbmc/reboot" },
              //   ]
              // }
            ]}
          />}
          toolsHide={true}
          content={
            <Switch>
              <Route exact path="/" component={Home}/>
              <Route path="/bastion/session" component={XtermSession}/>
              <Route path="/totem/policy-viewer" component={PolicyViewer} />
              <Route path="/totem/policy-generator" component={PolicyGenerator}/>
            </Switch>
          }
        />
      </HashRouter>
    );
  }
}

export default App;
