/* tslint:disable */
/* eslint-disable */
/**
 * ConsoleDreamscapeApp
 * Console Dreamscape App
 *
 * The version of the OpenAPI document: 2017-07-25
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export enum ApprovalStatus {
    Rejected = 'rejected',
    Approved = 'approved'
}

/**
 * 
 * @export
 * @interface AvailabilityZone
 */
export interface AvailabilityZone {
    /**
     * 
     * @type {string}
     * @memberof AvailabilityZone
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface BMC
 */
export interface BMC {
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'availabilityZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'fabric'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'macAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'hardwareId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'consoleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'type'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BMC
     */
    'configured'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'productVersion'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'ipAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'bmcFirmware'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'consoleOk'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMC
     */
    'sourceType'?: string;
    /**
     * 
     * @type {Array<BMCCredentials>}
     * @memberof BMC
     */
    'bmcCredentials'?: Array<BMCCredentials>;
    /**
     * 
     * @type {BMCAuthorization}
     * @memberof BMC
     */
    'bmcAuthorization'?: BMCAuthorization;
}
/**
 * 
 * @export
 * @interface BMCAuthorization
 */
export interface BMCAuthorization {
    /**
     * 
     * @type {string}
     * @memberof BMCAuthorization
     */
    'op'?: string;
}
/**
 * 
 * @export
 * @interface BMCCredentials
 */
export interface BMCCredentials {
    /**
     * 
     * @type {string}
     * @memberof BMCCredentials
     */
    'username'?: string;
    /**
     * 
     * @type {string}
     * @memberof BMCCredentials
     */
    'password'?: string;
}
/**
 * 
 * @export
 * @interface ClientPing
 */
export interface ClientPing {
    /**
     * 
     * @type {string}
     * @memberof ClientPing
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPing
     */
    'desiredSessionState'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum ConnectionType {
    Client = 'client',
    Server = 'server',
    Observer = 'observer'
}

/**
 * 
 * @export
 * @interface CreateSessionRequest
 */
export interface CreateSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'macAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'hardwareId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'availabilityZone'?: string;
    /**
     * 
     * @type {Fabric}
     * @memberof CreateSessionRequest
     */
    'fabric'?: Fabric;
    /**
     * 
     * @type {SessionType}
     * @memberof CreateSessionRequest
     */
    'sessionType'?: SessionType;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'federationRoleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateSessionRequest
     */
    'federationAccountEmail'?: string;
}
/**
 * 
 * @export
 * @interface DownloadFileRequest
 */
export interface DownloadFileRequest {
    /**
     * 
     * @type {string}
     * @memberof DownloadFileRequest
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileRequest
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileRequest
     */
    'scriptName'?: string;
}
/**
 * 
 * @export
 * @interface DownloadFileResponse
 */
export interface DownloadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof DownloadFileResponse
     */
    'signedUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof DownloadFileResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DownloadFileResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DreamscapeFeature {
    FileUpload = 'fileUpload'
}

/**
 * 
 * @export
 * @interface DreamscapeMessage
 */
export interface DreamscapeMessage {
    /**
     * 
     * @type {string}
     * @memberof DreamscapeMessage
     */
    'action'?: string;
    /**
     * 
     * @type {string}
     * @memberof DreamscapeMessage
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DreamscapeMessage
     */
    'connectionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DreamscapeMessage
     */
    'data'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum Fabric {
    Ec2 = 'ec2',
    Production = 'production',
    Corporate = 'corporate'
}

/**
 * 
 * @export
 * @interface FabricZone
 */
export interface FabricZone {
    /**
     * 
     * @type {string}
     * @memberof FabricZone
     */
    'availabilityZones'?: string;
    /**
     * 
     * @type {string}
     * @memberof FabricZone
     */
    'fabric'?: string;
}
/**
 * 
 * @export
 * @interface GetBMCInfoRequest
 */
export interface GetBMCInfoRequest {
    /**
     * 
     * @type {string}
     * @memberof GetBMCInfoRequest
     */
    'macAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBMCInfoRequest
     */
    'hardwareId'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetBMCInfoRequest
     */
    'assetId'?: string;
}
/**
 * 
 * @export
 * @interface GetBMCInfoResponse
 */
export interface GetBMCInfoResponse {
    /**
     * 
     * @type {BMC}
     * @memberof GetBMCInfoResponse
     */
    'bmc'?: BMC;
    /**
     * 
     * @type {string}
     * @memberof GetBMCInfoResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetBMCInfoResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetTotemS3ObjectsDataResponse
 */
export interface GetTotemS3ObjectsDataResponse {
    /**
     * 
     * @type {string}
     * @memberof GetTotemS3ObjectsDataResponse
     */
    'contents'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetTotemS3ObjectsDataResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTotemS3ObjectsDataResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetTotemS3ObjectsResponse
 */
export interface GetTotemS3ObjectsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTotemS3ObjectsResponse
     */
    'objectsList'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GetTotemS3ObjectsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetTotemS3ObjectsResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListPosixGroupsResponse
 */
export interface ListPosixGroupsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListPosixGroupsResponse
     */
    'posixGroups'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListPosixGroupsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListPosixGroupsResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListPromotionResponse
 */
export interface ListPromotionResponse {
    /**
     * 
     * @type {Array<Promotion>}
     * @memberof ListPromotionResponse
     */
    'promotions'?: Array<Promotion>;
    /**
     * 
     * @type {string}
     * @memberof ListPromotionResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListPromotionResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListRegionsResponse
 */
export interface ListRegionsResponse {
    /**
     * 
     * @type {Regions}
     * @memberof ListRegionsResponse
     */
    'regions'?: Regions;
    /**
     * 
     * @type {string}
     * @memberof ListRegionsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListRegionsResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListRepositoryScriptsResponse
 */
export interface ListRepositoryScriptsResponse {
    /**
     * 
     * @type {string}
     * @memberof ListRepositoryScriptsResponse
     */
    'bucket'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListRepositoryScriptsResponse
     */
    'keys'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListRepositoryScriptsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListRepositoryScriptsResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListSessionScriptsRequest
 */
export interface ListSessionScriptsRequest {
    /**
     * 
     * @type {string}
     * @memberof ListSessionScriptsRequest
     */
    'sessionId'?: string;
}
/**
 * 
 * @export
 * @interface ListSessionScriptsResponse
 */
export interface ListSessionScriptsResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListSessionScriptsResponse
     */
    'scriptsName'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ListSessionScriptsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListSessionScriptsResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ListSessionsResponse
 */
export interface ListSessionsResponse {
    /**
     * 
     * @type {Array<Session>}
     * @memberof ListSessionsResponse
     */
    'sessions'?: Array<Session>;
    /**
     * 
     * @type {string}
     * @memberof ListSessionsResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ListSessionsResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface PTYSessionPing
 */
export interface PTYSessionPing {
    /**
     * 
     * @type {string}
     * @memberof PTYSessionPing
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTYSessionPing
     */
    'desiredSessionState'?: string;
    /**
     * 
     * @type {string}
     * @memberof PTYSessionPing
     */
    'ptySessionState'?: string;
}
/**
 * 
 * @export
 * @interface PTYSessionResize
 */
export interface PTYSessionResize {
    /**
     * 
     * @type {string}
     * @memberof PTYSessionResize
     */
    'sessionId'?: string;
    /**
     * 
     * @type {number}
     * @memberof PTYSessionResize
     */
    'cols'?: number;
    /**
     * 
     * @type {number}
     * @memberof PTYSessionResize
     */
    'rows'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PTYSessionState {
    Running = 'running',
    Terminated = 'terminated',
    Unknown = 'unknown'
}

/**
 * 
 * @export
 * @interface PingResponse
 */
export interface PingResponse {
    /**
     * 
     * @type {SessionState}
     * @memberof PingResponse
     */
    'sessionState'?: SessionState;
    /**
     * 
     * @type {PTYSessionState}
     * @memberof PingResponse
     */
    'ptySessionState'?: PTYSessionState;
    /**
     * 
     * @type {ConnectionType}
     * @memberof PingResponse
     */
    'connectionType'?: ConnectionType;
    /**
     * 
     * @type {WebSocketCommand}
     * @memberof PingResponse
     */
    'webSocketCommand'?: WebSocketCommand;
    /**
     * 
     * @type {ClientPing}
     * @memberof PingResponse
     */
    'clientPing'?: ClientPing;
    /**
     * 
     * @type {SessionPong}
     * @memberof PingResponse
     */
    'pong'?: SessionPong;
    /**
     * 
     * @type {PTYSessionPing}
     * @memberof PingResponse
     */
    'ptySessionPing'?: PTYSessionPing;
    /**
     * 
     * @type {PTYSessionResize}
     * @memberof PingResponse
     */
    'ptySessionResize'?: PTYSessionResize;
    /**
     * 
     * @type {DreamscapeMessage}
     * @memberof PingResponse
     */
    'dreamscapeMessage'?: DreamscapeMessage;
}
/**
 * 
 * @export
 * @interface PromoteRequest
 */
export interface PromoteRequest {
    /**
     * 
     * @type {string}
     * @memberof PromoteRequest
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromoteRequest
     */
    'scriptName'?: string;
    /**
     * 
     * @type {string}
     * @memberof PromoteRequest
     */
    'posixGroup'?: string;
}
/**
 * 
 * @export
 * @interface PromoteResponse
 */
export interface PromoteResponse {
    /**
     * 
     * @type {Promotion}
     * @memberof PromoteResponse
     */
    'promotion'?: Promotion;
    /**
     * 
     * @type {string}
     * @memberof PromoteResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PromoteResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Promotion
 */
export interface Promotion {
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'uuid'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'scriptName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'requester'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'posixGroup'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'approvalStatus'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'createdAt'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'comment'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'lastReviewedBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Promotion
     */
    'lastReviewedAt'?: string;
}
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'regionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    'airportCode'?: string;
    /**
     * 
     * @type {Array<AvailabilityZone>}
     * @memberof Region
     */
    'availabilityZones'?: Array<AvailabilityZone>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Region
     */
    'fabrics'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Regions
 */
export interface Regions {
    /**
     * 
     * @type {Array<Region>}
     * @memberof Regions
     */
    'regions'?: Array<Region>;
    /**
     * 
     * @type {Region}
     * @memberof Regions
     */
    'currentRegion'?: Region;
}
/**
 * 
 * @export
 * @interface ReviewRequest
 */
export interface ReviewRequest {
    /**
     * 
     * @type {string}
     * @memberof ReviewRequest
     */
    'uuId'?: string;
    /**
     * 
     * @type {ApprovalStatus}
     * @memberof ReviewRequest
     */
    'approvalStatus'?: ApprovalStatus;
    /**
     * 
     * @type {string}
     * @memberof ReviewRequest
     */
    'comment'?: string;
}
/**
 * 
 * @export
 * @interface ReviewResponse
 */
export interface ReviewResponse {
    /**
     * 
     * @type {Promotion}
     * @memberof ReviewResponse
     */
    'promotion'?: Promotion;
    /**
     * 
     * @type {string}
     * @memberof ReviewResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ReviewResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Session
 */
export interface Session {
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'sessionState'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'sessionStateReason'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'sessionName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'sessionType'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Session
     */
    'sessionIsolation'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'federationAccountEmail'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'federationRoleName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'ptySessionState'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'ptySessionWorker'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'userName'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'macAddress'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'hardwareId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'assetId'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'availabilityZone'?: string;
    /**
     * 
     * @type {string}
     * @memberof Session
     */
    'fabric'?: string;
}
/**
 * 
 * @export
 * @interface SessionPong
 */
export interface SessionPong {
    /**
     * 
     * @type {string}
     * @memberof SessionPong
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionPong
     */
    'sessionState'?: string;
}
/**
 * 
 * @export
 * @interface SessionRequest
 */
export interface SessionRequest {
    /**
     * 
     * @type {string}
     * @memberof SessionRequest
     */
    'sessionId'?: string;
}
/**
 * 
 * @export
 * @interface SessionResponse
 */
export interface SessionResponse {
    /**
     * 
     * @type {Session}
     * @memberof SessionResponse
     */
    'session'?: Session;
    /**
     * 
     * @type {string}
     * @memberof SessionResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SessionResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum SessionState {
    New = 'new',
    Active = 'active',
    Grace = 'grace',
    Closed = 'closed'
}

/**
 * 
 * @export
 * @enum {string}
 */

export enum SessionType {
    Tmux = 'tmux',
    Sol = 'sol',
    Inception = 'inception',
    Shell = 'shell'
}

/**
 * 
 * @export
 * @interface StartSessionRequest
 */
export interface StartSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof StartSessionRequest
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof StartSessionRequest
     */
    'federationData'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSessionRequest
 */
export interface UpdateSessionRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSessionRequest
     */
    'sessionName'?: string;
}
/**
 * 
 * @export
 * @interface UploadFileRequest
 */
export interface UploadFileRequest {
    /**
     * 
     * @type {string}
     * @memberof UploadFileRequest
     */
    'sessionId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileRequest
     */
    'fileName'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileRequest
     */
    'contentType'?: string;
}
/**
 * 
 * @export
 * @interface UploadFileResponse
 */
export interface UploadFileResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'signedUrl'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'bucket'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'key'?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadFileResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UploadFileResponse
     */
    'errors'?: Array<string>;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userName'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'groupNames'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    'sessionTypes'?: Array<string>;
    /**
     * 
     * @type {Array<FabricZone>}
     * @memberof User
     */
    'fabricZones'?: Array<FabricZone>;
    /**
     * 
     * @type {Array<DreamscapeFeature>}
     * @memberof User
     */
    'features'?: Array<DreamscapeFeature>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum WebSocketCommand {
    Data = 'data',
    Ping = 'ping',
    Pong = 'pong',
    Reset = 'reset',
    Resize = 'resize',
    Replay = 'replay',
    FileUploadNotify = 'file_upload_notify'
}

/**
 * 
 * @export
 * @interface WhoAmIResponse
 */
export interface WhoAmIResponse {
    /**
     * 
     * @type {User}
     * @memberof WhoAmIResponse
     */
    'user'?: User;
    /**
     * 
     * @type {string}
     * @memberof WhoAmIResponse
     */
    'error'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WhoAmIResponse
     */
    'errors'?: Array<string>;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bmcInfoOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/bmc-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest SessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeSession: async (sessionRequest: SessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionRequest' is not null or undefined
            assertParamExists('closeSession', 'sessionRequest', sessionRequest)
            const localVarPath = `/close-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeSessionOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/close-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateSessionRequest} createSessionRequest CreateSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession: async (createSessionRequest: CreateSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createSessionRequest' is not null or undefined
            assertParamExists('createSession', 'createSessionRequest', createSessionRequest)
            const localVarPath = `/create-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/create-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DownloadFileRequest} downloadFileRequest DownloadFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile: async (downloadFileRequest: DownloadFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'downloadFileRequest' is not null or undefined
            assertParamExists('downloadFile', 'downloadFileRequest', downloadFileRequest)
            const localVarPath = `/download-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(downloadFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFileOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/download-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetBMCInfoRequest} getBMCInfoRequest GetBMCInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBMCInfo: async (getBMCInfoRequest: GetBMCInfoRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getBMCInfoRequest' is not null or undefined
            assertParamExists('getBMCInfo', 'getBMCInfoRequest', getBMCInfoRequest)
            const localVarPath = `/bmc-info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getBMCInfoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest SessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession: async (sessionRequest: SessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionRequest' is not null or undefined
            assertParamExists('getSession', 'sessionRequest', sessionRequest)
            const localVarPath = `/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bucketName] bucketName
         * @param {string} [prefix] prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotemS3Objects: async (bucketName?: string, prefix?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-totem-s3-objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bucketName !== undefined) {
                localVarQueryParameter['bucket_name'] = bucketName;
            }

            if (prefix !== undefined) {
                localVarQueryParameter['prefix'] = prefix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [bucketName] bucketName
         * @param {string} [key] key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotemS3ObjectsData: async (bucketName?: string, key?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-totem-s3-objects-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (bucketName !== undefined) {
                localVarQueryParameter['bucket_name'] = bucketName;
            }

            if (key !== undefined) {
                localVarQueryParameter['key'] = key;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotemS3ObjectsDataOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-totem-s3-objects-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotemS3ObjectsOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/get-totem-s3-objects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveSessions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-active-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveSessionsOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-active-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPosixGroups: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-posix-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPosixGroupsOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-posix-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromotion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromotionOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-promotion`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromotionReview: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-promotion-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromotionReviewOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-promotion-review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRepositoryScripts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-repository-scripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRepositoryScriptsOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-repository-scripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ListSessionScriptsRequest} listSessionScriptsRequest ListSessionScriptsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessionScripts: async (listSessionScriptsRequest: ListSessionScriptsRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'listSessionScriptsRequest' is not null or undefined
            assertParamExists('listSessionScripts', 'listSessionScriptsRequest', listSessionScriptsRequest)
            const localVarPath = `/list-session-scripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(listSessionScriptsRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessionScriptsOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-session-scripts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessionsOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/list-sessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PromoteRequest} promoteRequest PromoteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promote: async (promoteRequest: PromoteRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'promoteRequest' is not null or undefined
            assertParamExists('promote', 'promoteRequest', promoteRequest)
            const localVarPath = `/promote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(promoteRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoteOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/promote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/regions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReviewRequest} reviewRequest ReviewRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        review: async (reviewRequest: ReviewRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reviewRequest' is not null or undefined
            assertParamExists('review', 'reviewRequest', reviewRequest)
            const localVarPath = `/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reviewRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/review`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StartSessionRequest} startSessionRequest StartSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSession: async (startSessionRequest: StartSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'startSessionRequest' is not null or undefined
            assertParamExists('startSession', 'startSessionRequest', startSessionRequest)
            const localVarPath = `/start-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(startSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSessionOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/start-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSessionRequest} updateSessionRequest UpdateSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSession: async (updateSessionRequest: UpdateSessionRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSessionRequest' is not null or undefined
            assertParamExists('updateSession', 'updateSessionRequest', updateSessionRequest)
            const localVarPath = `/update-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSessionRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/update-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UploadFileRequest} uploadFileRequest UploadFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile: async (uploadFileRequest: UploadFileRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadFileRequest' is not null or undefined
            assertParamExists('uploadFile', 'uploadFileRequest', uploadFileRequest)
            const localVarPath = `/upload-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(uploadFileRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/upload-file`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication sigv4 required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoamiOptions: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/whoami`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'OPTIONS', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async bmcInfoOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.bmcInfoOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest SessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeSession(sessionRequest: SessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeSession(sessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async closeSessionOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.closeSessionOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CreateSessionRequest} createSessionRequest CreateSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSession(createSessionRequest: CreateSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSession(createSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createSessionOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createSessionOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DownloadFileRequest} downloadFileRequest DownloadFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFile(downloadFileRequest: DownloadFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DownloadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFile(downloadFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadFileOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadFileOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GetBMCInfoRequest} getBMCInfoRequest GetBMCInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBMCInfo(getBMCInfoRequest: GetBMCInfoRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetBMCInfoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBMCInfo(getBMCInfoRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest SessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSession(sessionRequest: SessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSession(sessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bucketName] bucketName
         * @param {string} [prefix] prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotemS3Objects(bucketName?: string, prefix?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTotemS3ObjectsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotemS3Objects(bucketName, prefix, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [bucketName] bucketName
         * @param {string} [key] key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotemS3ObjectsData(bucketName?: string, key?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTotemS3ObjectsDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotemS3ObjectsData(bucketName, key, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotemS3ObjectsDataOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotemS3ObjectsDataOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTotemS3ObjectsOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTotemS3ObjectsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActiveSessions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSessionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActiveSessions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listActiveSessionsOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listActiveSessionsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPosixGroups(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPosixGroupsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPosixGroups(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPosixGroupsOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPosixGroupsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPromotion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPromotion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPromotionOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPromotionOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPromotionReview(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListPromotionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPromotionReview(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listPromotionReviewOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listPromotionReviewOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRegions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRegionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRegions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRepositoryScripts(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListRepositoryScriptsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRepositoryScripts(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listRepositoryScriptsOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listRepositoryScriptsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ListSessionScriptsRequest} listSessionScriptsRequest ListSessionScriptsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSessionScripts(listSessionScriptsRequest: ListSessionScriptsRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSessionScriptsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSessionScripts(listSessionScriptsRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSessionScriptsOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSessionScriptsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSessions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListSessionsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSessions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSessionsOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSessionsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ping(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PingResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PromoteRequest} promoteRequest PromoteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promote(promoteRequest: PromoteRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PromoteResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promote(promoteRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async promoteOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.promoteOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async regionsOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.regionsOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReviewRequest} reviewRequest ReviewRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async review(reviewRequest: ReviewRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReviewResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.review(reviewRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reviewOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reviewOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sessionOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sessionOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StartSessionRequest} startSessionRequest StartSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSession(startSessionRequest: StartSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSession(startSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async startSessionOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.startSessionOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UpdateSessionRequest} updateSessionRequest UpdateSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSession(updateSessionRequest: UpdateSessionRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSession(updateSessionRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateSessionOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateSessionOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UploadFileRequest} uploadFileRequest UploadFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFile(uploadFileRequest: UploadFileRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadFileResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFile(uploadFileRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFileOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFileOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whoAmI(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhoAmIResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whoAmI(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async whoamiOptions(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.whoamiOptions(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        bmcInfoOptions(options?: any): AxiosPromise<void> {
            return localVarFp.bmcInfoOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest SessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeSession(sessionRequest: SessionRequest, options?: any): AxiosPromise<SessionResponse> {
            return localVarFp.closeSession(sessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        closeSessionOptions(options?: any): AxiosPromise<void> {
            return localVarFp.closeSessionOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateSessionRequest} createSessionRequest CreateSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSession(createSessionRequest: CreateSessionRequest, options?: any): AxiosPromise<SessionResponse> {
            return localVarFp.createSession(createSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSessionOptions(options?: any): AxiosPromise<void> {
            return localVarFp.createSessionOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DownloadFileRequest} downloadFileRequest DownloadFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFile(downloadFileRequest: DownloadFileRequest, options?: any): AxiosPromise<DownloadFileResponse> {
            return localVarFp.downloadFile(downloadFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadFileOptions(options?: any): AxiosPromise<void> {
            return localVarFp.downloadFileOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetBMCInfoRequest} getBMCInfoRequest GetBMCInfoRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBMCInfo(getBMCInfoRequest: GetBMCInfoRequest, options?: any): AxiosPromise<GetBMCInfoResponse> {
            return localVarFp.getBMCInfo(getBMCInfoRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SessionRequest} sessionRequest SessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSession(sessionRequest: SessionRequest, options?: any): AxiosPromise<SessionResponse> {
            return localVarFp.getSession(sessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bucketName] bucketName
         * @param {string} [prefix] prefix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotemS3Objects(bucketName?: string, prefix?: string, options?: any): AxiosPromise<GetTotemS3ObjectsResponse> {
            return localVarFp.getTotemS3Objects(bucketName, prefix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [bucketName] bucketName
         * @param {string} [key] key
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotemS3ObjectsData(bucketName?: string, key?: string, options?: any): AxiosPromise<GetTotemS3ObjectsDataResponse> {
            return localVarFp.getTotemS3ObjectsData(bucketName, key, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotemS3ObjectsDataOptions(options?: any): AxiosPromise<void> {
            return localVarFp.getTotemS3ObjectsDataOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTotemS3ObjectsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.getTotemS3ObjectsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveSessions(options?: any): AxiosPromise<ListSessionsResponse> {
            return localVarFp.listActiveSessions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listActiveSessionsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.listActiveSessionsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPosixGroups(options?: any): AxiosPromise<ListPosixGroupsResponse> {
            return localVarFp.listPosixGroups(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPosixGroupsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.listPosixGroupsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromotion(options?: any): AxiosPromise<ListPromotionResponse> {
            return localVarFp.listPromotion(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromotionOptions(options?: any): AxiosPromise<void> {
            return localVarFp.listPromotionOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromotionReview(options?: any): AxiosPromise<ListPromotionResponse> {
            return localVarFp.listPromotionReview(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listPromotionReviewOptions(options?: any): AxiosPromise<void> {
            return localVarFp.listPromotionReviewOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRegions(options?: any): AxiosPromise<ListRegionsResponse> {
            return localVarFp.listRegions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRepositoryScripts(options?: any): AxiosPromise<ListRepositoryScriptsResponse> {
            return localVarFp.listRepositoryScripts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listRepositoryScriptsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.listRepositoryScriptsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ListSessionScriptsRequest} listSessionScriptsRequest ListSessionScriptsRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessionScripts(listSessionScriptsRequest: ListSessionScriptsRequest, options?: any): AxiosPromise<ListSessionScriptsResponse> {
            return localVarFp.listSessionScripts(listSessionScriptsRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessionScriptsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.listSessionScriptsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessions(options?: any): AxiosPromise<ListSessionsResponse> {
            return localVarFp.listSessions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSessionsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.listSessionsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ping(options?: any): AxiosPromise<PingResponse> {
            return localVarFp.ping(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingOptions(options?: any): AxiosPromise<void> {
            return localVarFp.pingOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PromoteRequest} promoteRequest PromoteRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promote(promoteRequest: PromoteRequest, options?: any): AxiosPromise<PromoteResponse> {
            return localVarFp.promote(promoteRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        promoteOptions(options?: any): AxiosPromise<void> {
            return localVarFp.promoteOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        regionsOptions(options?: any): AxiosPromise<void> {
            return localVarFp.regionsOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReviewRequest} reviewRequest ReviewRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        review(reviewRequest: ReviewRequest, options?: any): AxiosPromise<ReviewResponse> {
            return localVarFp.review(reviewRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reviewOptions(options?: any): AxiosPromise<void> {
            return localVarFp.reviewOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sessionOptions(options?: any): AxiosPromise<void> {
            return localVarFp.sessionOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StartSessionRequest} startSessionRequest StartSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSession(startSessionRequest: StartSessionRequest, options?: any): AxiosPromise<SessionResponse> {
            return localVarFp.startSession(startSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        startSessionOptions(options?: any): AxiosPromise<void> {
            return localVarFp.startSessionOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSessionRequest} updateSessionRequest UpdateSessionRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSession(updateSessionRequest: UpdateSessionRequest, options?: any): AxiosPromise<SessionResponse> {
            return localVarFp.updateSession(updateSessionRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSessionOptions(options?: any): AxiosPromise<void> {
            return localVarFp.updateSessionOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UploadFileRequest} uploadFileRequest UploadFileRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFile(uploadFileRequest: UploadFileRequest, options?: any): AxiosPromise<UploadFileResponse> {
            return localVarFp.uploadFile(uploadFileRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFileOptions(options?: any): AxiosPromise<void> {
            return localVarFp.uploadFileOptions(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoAmI(options?: any): AxiosPromise<WhoAmIResponse> {
            return localVarFp.whoAmI(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        whoamiOptions(options?: any): AxiosPromise<void> {
            return localVarFp.whoamiOptions(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public bmcInfoOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).bmcInfoOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SessionRequest} sessionRequest SessionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public closeSession(sessionRequest: SessionRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).closeSession(sessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public closeSessionOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).closeSessionOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateSessionRequest} createSessionRequest CreateSessionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSession(createSessionRequest: CreateSessionRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSession(createSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createSessionOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).createSessionOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DownloadFileRequest} downloadFileRequest DownloadFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadFile(downloadFileRequest: DownloadFileRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadFile(downloadFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public downloadFileOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).downloadFileOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetBMCInfoRequest} getBMCInfoRequest GetBMCInfoRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getBMCInfo(getBMCInfoRequest: GetBMCInfoRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getBMCInfo(getBMCInfoRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SessionRequest} sessionRequest SessionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getSession(sessionRequest: SessionRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getSession(sessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bucketName] bucketName
     * @param {string} [prefix] prefix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTotemS3Objects(bucketName?: string, prefix?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTotemS3Objects(bucketName, prefix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [bucketName] bucketName
     * @param {string} [key] key
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTotemS3ObjectsData(bucketName?: string, key?: string, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTotemS3ObjectsData(bucketName, key, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTotemS3ObjectsDataOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTotemS3ObjectsDataOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getTotemS3ObjectsOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getTotemS3ObjectsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listActiveSessions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listActiveSessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listActiveSessionsOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listActiveSessionsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPosixGroups(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPosixGroups(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPosixGroupsOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPosixGroupsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPromotion(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPromotion(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPromotionOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPromotionOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPromotionReview(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPromotionReview(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listPromotionReviewOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listPromotionReviewOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRegions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listRegions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRepositoryScripts(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listRepositoryScripts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listRepositoryScriptsOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listRepositoryScriptsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ListSessionScriptsRequest} listSessionScriptsRequest ListSessionScriptsRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSessionScripts(listSessionScriptsRequest: ListSessionScriptsRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSessionScripts(listSessionScriptsRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSessionScriptsOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSessionScriptsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSessions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSessions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listSessionsOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).listSessionsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public ping(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).ping(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public pingOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).pingOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PromoteRequest} promoteRequest PromoteRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public promote(promoteRequest: PromoteRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).promote(promoteRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public promoteOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).promoteOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public regionsOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).regionsOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ReviewRequest} reviewRequest ReviewRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public review(reviewRequest: ReviewRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).review(reviewRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public reviewOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).reviewOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public sessionOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).sessionOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {StartSessionRequest} startSessionRequest StartSessionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public startSession(startSessionRequest: StartSessionRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).startSession(startSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public startSessionOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).startSessionOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSessionRequest} updateSessionRequest UpdateSessionRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSession(updateSessionRequest: UpdateSessionRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSession(updateSessionRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public updateSessionOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).updateSessionOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UploadFileRequest} uploadFileRequest UploadFileRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadFile(uploadFileRequest: UploadFileRequest, options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadFile(uploadFileRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public uploadFileOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).uploadFileOptions(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public whoAmI(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).whoAmI(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public whoamiOptions(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).whoamiOptions(options).then((request) => request(this.axios, this.basePath));
    }
}


